<ion-header>
  <ion-toolbar>
    <ion-title>Announcement</ion-title>
    
    <ion-buttons slot="end">
      <ion-button icon-only (click)="closeModal()" class="close-button-modal">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="isViewLoading">
    <div class='is-loading-container'>
      <ion-spinner color='tertiary'></ion-spinner>
    </div>
  </div>

  <div *ngIf="!isViewLoading">
    <div style="padding:20px 30px;margin:0;margin-bottom: 10px;" [innerHTML]="announcement.content"></div>
  </div>
</ion-content>

