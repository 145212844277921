import { Component, NgZone } from '@angular/core';

import { Platform, AlertController, LoadingController, NavController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Storage } from '@ionic/storage';

import { EventService } from './event.service';
import { TranslateService } from '@ngx-translate/core';
import { NavParamsService } from 'src/app/nav-params.service';

import axios from 'axios';
import config from '../config';
import { codePush } from 'capacitor-codepush';
import { SunfishSsoClientService } from './services/sunfish-sso-client/sunfish-sso-client.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  /**
   * Token name
   */
  TOKEN: string = config.ACCESS_TOKEN_IDENTIFIER;

  /**
   * Api Url
   */
  API_URL: string = config.API_URL;

  constructor(
    private platform: Platform,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    public events: EventService,
    private storage: Storage,
    private translate: TranslateService,
    private oneSignal: OneSignal,
    public navParams: NavParamsService,
    private zone: NgZone,
    private sunfishSsoClient: SunfishSsoClientService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.storage.create();
    this.platform.ready().then(async () => {
      StatusBar.setBackgroundColor({
        color: '#4819e3'
      });
      StatusBar.setStyle({
        style: Style.Dark
      })

      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        SplashScreen.hide();
      }

      // Start one signal
      this.oneSignal.startInit(config.ONE_SIGNAL_KEY, config.SENDER_ID);
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
      this.oneSignal.handleNotificationReceived().subscribe(notif => {
        // do something when notification is received
        console.log(notif);
      });
      this.oneSignal.handleNotificationOpened().subscribe(async (data) => {
        // do something when notification is opened
        const token = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
        if(token == null) {
          return false;
        }

        console.log(data);
        
        setTimeout(() => {
          this.zone.run(() => {
            if (data.notification.payload.additionalData.target_page !== null && data.notification.payload.additionalData.target_page !== undefined) {
              if (data.notification.payload.additionalData.nav_params !== null && data.notification.payload.additionalData.nav_params !== undefined) {
                this.navParams.setNavParams(data.notification.payload.additionalData.nav_params);
              }
              console.log(data.notification.payload.additionalData.target_page);
              this.navCtrl.navigateForward(data.notification.payload.additionalData.target_page);
            }
          });
        }, 300);
      });

      this.oneSignal.endInit();

      // Codepush in background

      // const downloadProgress = (progress) => { console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`); };
      // this.codePush.sync({}, downloadProgress).subscribe(syncStatus => {
      //   console.log(syncStatus);
      // });

      // Codepush in background if in cordova/capacitor
      if (this.platform.is('cordova') || this.platform.is('capacitor')) {
        await codePush.sync({
          onSyncStatusChanged: (data) => {
            console.log('Code push status', data);
          },
          onSyncError: (error) => {
            console.log(error);
            console.log('Failed to sync');
          }
        }, (downloadProgress) => {
          console.log("Downloading " + downloadProgress.receivedBytes + " of " + downloadProgress.totalBytes);
        });
      }
    });

    this.storage.get('language').then(language => {
      if (language === undefined || language === null || language.length <= 0) {
        language = 'en';
      }
      this.translate.setDefaultLang(language);
    });

    this.events.subscribe('app:changed_language', data => {
      const language = data.language;
      this.storage.set('language', language).then(() => {
        this.translate.setDefaultLang(language);
      });
    });

    this.events.subscribe('user:login', () => {
      this.handleLogin();
    });

    this.events.subscribe('user:logout', () => {
      this.doLogout();
    });

    this.storage.get(this.TOKEN).then((accessToken) => {
      // tslint:disable-next-line: triple-equals
      if (accessToken != null && accessToken != undefined && accessToken.length > 0) {
        this.events.publish('user:login');
      } else {
        this.events.publish('user:logout');
      }
    });

    this.events.subscribe('SUNFISH_SSO:OPEN_LINK', async () => {
      const loader = await this.loadingCtrl.create({
        message: 'Loading...'
      });
      loader.present();

      const result = await this.sunfishSsoClient.requestToken()
      if (result.error) {
        loader.dismiss();
        this.alertCtrl.create({
          header: "Error",
          message: result.errorMessage,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
        return
      }

      loader.dismiss();
      this.sunfishSsoClient.openSSOPage(result?.data?.url);
    });
  }

  /**
   * Redirects the user to the logged in home page when
   * the "user:login" event is transmitted
   */
  async handleLogin() {
    // const loader = await this.loadingCtrl.create({
    //   message: 'Loading...'
    // });

    // await loader.present();
    const accessToken = await this.storage.get(this.TOKEN);
    /**
     * check the access token. Logged out the user if the access token is empty
     */
    if (accessToken == null || accessToken === undefined || accessToken.length <= 0) {
      // loader.dismiss();
      this.events.publish('user:logout');
      return false;
    }

    axios.get(`${this.API_URL}/auth/user-data`, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }).then(async (response) => {
      const data = response.data;
      if (data.error) {
        if (data.error_code === 401 || data.error_code === '401') {
          this.events.publish('user:logout');
        } else {
          const alert = await this.alertCtrl.create({
            header: 'Error',
            message : data.message,
            buttons: ['Ok']
          });
          alert.present();
        }
        return false;
      }

      // loader.dismiss();
      this.navCtrl.navigateRoot('/home');


      // Upload Onesignal token
      setTimeout(() => {
        this.oneSignal.getIds().then(onesignalData => {
          axios.post(`${config.API_URL}/onesignal/save-device`, {
            user_id: onesignalData.userId,
            push_token: onesignalData.pushToken
          }, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }).then(onesignalResponse => {
            // loader.dismiss();
            // this.navCtrl.navigateRoot('/home');
          }).catch(error => {
            console.log(error);
            // loader.dismiss();
            // this.navCtrl.navigateRoot('/home');
          });
        }).catch(error => {
          // loader.dismiss();
          // this.navCtrl.navigateRoot('/home');
        });
      }, 200);
    }).catch(async (error) => {
      // loader.dismiss();
      if (error.status === 401) {
        this.events.publish('user:logout');
        return false;
      }
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please log in again.',
        buttons: ['Ok']
      });
      alert.present();
    });
  }

  /**
   * Logs the user out and resets them to the login page
   */
  async doLogout() {
    await this.storage.remove(this.TOKEN);
    this.navCtrl.navigateRoot('/login');
  }
}
