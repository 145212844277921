<ion-header>
  <ion-toolbar>
    <ion-title>{{ formatNumber.formatLikeNumber(comment.likes_count) }} like{{ comment.likes_count > 1 ? 's' : '' }}</ion-title>
    
    <ion-buttons slot="end">
      <ion-button icon-only (click)="closeModal()" class="close-button-modal">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="isViewLoading">
    <div class='is-loading-container'>
      <ion-spinner color='tertiary'></ion-spinner>
    </div>
  </div>

  <div *ngIf="!isViewLoading">
    <div *ngIf="likes.length == 0">
      <h4 style="text-align: center; margin-top: 20px; font-size: 15px;">No likes</h4>
    </div>
    <ion-list>
      <ion-item *ngFor="let like of likes" lines="full">
        <ion-avatar slot="start">
          <img src="{{ like.user.signed_profile_picture_url }}">
        </ion-avatar>
        <ion-label>
          <div style="line-height: 1;">
            <code style="font-size: 11px;color: #a2a2a2;font-weight: bold;">{{ like.user.employee_number }}</code>
            <h4 style="margin-top: 0; font-size: 14px;">{{ like.user.first_name + ' ' + like.user.last_name }}</h4>
          </div>  
        </ion-label>
      </ion-item>
    </ion-list>
  </div>
</ion-content>
