import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController, NavController } from '@ionic/angular';
import axios from 'axios';
import config from 'src/config';
import { Storage } from '@ionic/storage';
import { NavParamsService } from 'src/app/nav-params.service';
import { CreateGroupChatPage } from './create-group-chat/create-group-chat.page';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-create-chat',
  templateUrl: './create-chat.page.html',
  styleUrls: ['./create-chat.page.scss'],
})
export class CreateChatPage implements OnInit {

  public hasSearched = false;
  public isLoading = false;
  public employeeCode = '';
  public employeeName = '';
  public employeeJobTitle = '';
  public employees = [];

  constructor(
    public modalCtrl: ModalController,
    public storage: Storage,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public navParams: NavParamsService,
    public navCtrl: NavController,
    public events: EventService
  ) {
    this.events.subscribe('create-group-chat-modal:dismiss', () => {
      this.modalCtrl.dismiss().then(() => {
        this.navCtrl.navigateForward('/live-chat/chat-room');
      });
    });
  }

  async startGroupChat() {
    this.modalCtrl.create({
      component: CreateGroupChatPage,
      cssClass: 'create-chat-modal-wrapper'
    }).then(async (modal) => {
      modal.present();
    });
  }

  async startEmployeeChat(employee) {
    const loader = await this.loadingCtrl.create({
      message: 'Starting chat...'
    });
    await loader.present();

    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/start-individual-chat`, {
      user_id: employee.id
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      loader.dismiss();
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.navParams.setNavParams({
          chat_room: data.chat_room
        });
        this.modalCtrl.dismiss().then(() => {
          this.navCtrl.navigateForward('/live-chat/chat-room');
        });
      }
    }).catch(error => {
      loader.dismiss();
      console.error(error);
    });
  }

  async searchEmployees() {
    this.isLoading = true;
    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/search-employees`, {
      employee_code: this.employeeCode,
      employee_name: this.employeeName,
      employee_job_title: this.employeeJobTitle
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      this.isLoading = false;
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.hasSearched = true;
        this.employees = data.users;
      }
    }).catch(error => {
      this.isLoading = false;
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
