<ion-header>
  <ion-toolbar color='tertiary'>
    <ion-buttons slot='start'>
      <ion-button (click)='closeModal()'>
        <ion-icon name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Upload File</ion-title>
    <ion-icon slot="end" name="home-outline" class="home-icon" (click)="home()"></ion-icon>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-list-header>Upload File</ion-list-header>
    <ion-item lines="none">
      Select File
    </ion-item>
    <ion-item lines="full">
      <input type="file" name="file" id="fileUploadInput" />
    </ion-item>
    <ion-item lines="full">
      <ion-textarea
        placeholder="Message... (Optional)"
        [(ngModel)]="uploadFileMessage"
        rows="4"
      ></ion-textarea>
    </ion-item>
  </ion-list>
  <ion-button (click)="uploadFile()" *ngIf="!isLoading" color='primary' style='display: block; width: 80%; margin: 0 auto; margin-top: 20px'>Upload File</ion-button>
  <ion-button *ngIf="isLoading" disabled="true" color='primary' style='display: block; width: 80%; margin: 0 auto; margin-top: 20px'>
    <ion-spinner></ion-spinner>
    Upload File
  </ion-button>
</ion-content>
