let config = {
    ENV: 'web',
    IFRAME_URL : "https://city-family.citycloudmm.com/iframes",
    // IFRAME_URL : "http://localhost:8000/iframes",
    WEBSOCKET_URL : "https://ws.city-family.citycloudmm.com",
    WEBSOCKET_PREFIX: 'cityfamilylive_database',
    API_URL: 'https://city-family.citycloudmm.com/api',
    SITE_URL: 'https://city-family.citycloudmm.com',
    // API_URL: 'http://localhost:8000/api',
    // SITE_URL: 'http://localhost:8000',

    USER_DATA_IDENTIFIER: 'CityFamilyUserData',
    CHECKIN_DATA_IDENTIFIER: 'CityFamilyCheckinData',
    CAN_DO_OFFLINE_ACCESS: 'CityFamilyCanDoOfflineAccess',
    CAN_DO_OFFLINE_ATTENDANCE: 'CityFamilyCanDoOfflineAttendance',

    ACCESS_TOKEN_IDENTIFIER: 'CityFamilyAccessToken',
    ONE_SIGNAL_KEY: '04141ded-339e-45d8-81a9-20748535e140',
    SENDER_ID: '877823333871',

    HELPDESK_ACCESS_URL_IDENTIFIER: 'CityFamilyHelpdeskAccessUrl',
    HELPDESK_ACCESS_TOKEN_IDENTIFIER: 'CityFamilyHelpdeskAccessToken',
    HELPDESK_ACCESS_VERSION_IDENTIFIER: 'CityFamilyHelpdeskAccessVersion',
    HELPDESK_ACCESS_MODE_IDENTIFIER: 'CityFamilyHelpdeskAccessMode',
    HELPDESK_ACCESS_UPLOAD_IMAGE_ON_TICKET_CREATION_IDENTIFIER: 'CityFamilyHelpdeskAccessUploadImageOnTicketCreation',

    STORE_OPERATIONS_ACCESS_TOKEN_IDENTIFIER: 'CityFamilyStoreOperationsAccessToken',
    STORE_OPERATIONS_ACCESS_URL_IDENTIFIER: 'CityFamilyStoreOperationsAccessUrl',

    STORE_OPERATIONS_CITY_PROPERTIES_ACCESS_TOKEN_IDENTIFIER: 'CityFamilyStoreOperationsCityPropertiesAccessToken',
    STORE_OPERATIONS_CITY_PROPERTIES_ACCESS_URL_IDENTIFIER: 'CityFamilyStoreOperationsCityPropertiesAccessUrl',
    
    E_LEARNING_FIELD_IMMERSION_LINK: 'https://outlook.office365.com/book/FieldImmersionRegistration@cmhl.com.mm/',
    E_LEARNING_THE_FUTURE_U_LINK: 'https://forms.office.com/Pages/ResponsePage.aspx?id=nJBpD27qfU6nwg4VoV2yU3NsonR3cDZOsp3KhBvsHKxUOUhLNzhIMlNWQjJQVURSU0ZMVVlHMzdNNi4u',
};
export default config;
