<ion-header>
  <ion-toolbar color='tertiary'>
    <ion-buttons slot='start'>
      <ion-button (click)='closeModal()'>
        Close
      </ion-button>
    </ion-buttons>
    <ion-title>More</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="cf-design">
  <div *ngIf="isLoading" style="padding-top: 20px; padding-bottom: 20px; text-align: center">
    <ion-spinner color='tertiary'></ion-spinner>
  </div>

  <div class="icon-panel" *ngIf="!isLoading" style="padding:10px !important;">
    <ion-grid>
      <!-- <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('bookings')">
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/Homepage/Bookings.png" style="width: 60px !important;" alt="Bookings">
              <p>Bookings</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="getToken()">
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/Homepage/City Jobs.png" style="width: 60px !important;" alt="City Job">
              <p>City Job</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="openPage('non-trade-feature')" >
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/Homepage/Non Trade Request.png" style="width: 60px !important;" alt="Non Trade Request">
              <p>Non Trade Request</p>
            </div>
          </div>
        </ion-col>
      </ion-row> -->

      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('qa-audit/home')">
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/QA Audit/QA Audit.png" style="width: 60px !important;" alt="QA Audit">
              <p>QA Audit</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <!-- <div class='nav-box' (click)="getToken()">
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/Homepage/City Jobs.png" style="width: 60px !important;" alt="City Job">
              <p>City Job</p>
            </div>
          </div> -->
        </ion-col>
        <ion-col>
          <!-- <div class='nav-box' (click)="openPage('non-trade-feature')" >
            <div class='nav-content' style="text-align: center;">
              <img src="/assets/images/CF/Homepage/Non Trade Request.png" style="width: 60px !important;" alt="Non Trade Request">
              <p>Non Trade Request</p>
            </div>
          </div> -->
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- <div class="new-design-selection" *ngIf="!isLoading">
    <ion-grid>
      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('bookings')">
            <div class='nav-content'>
              <img src="/assets/images/Homepage/Bookings.png" alt="Bookings">
              <p>Bookings</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="getToken()">
            <div class='nav-content'>
              <img src="/assets/images/Homepage/City Jobs.png" alt="City Job">
              <p>City Job</p>
            </div>
          </div>
        </ion-col>

        <ion-col>
          <div class='nav-box' (click)="openPage('non-trade-feature')" >
            <div class='nav-content'>
              <img src="/assets/images/Homepage/Non Trade Request.png" alt="Non Trade Request">
              <p>Non Trade Request</p>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('qa-audit/home')">
            <div class='nav-content'>
              <img src="/assets/images/store-audit.svg" alt="">
              <p>QA Audit</p>
            </div>
          </div>
        </ion-col>
        <ion-col></ion-col>
        <ion-col></ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('innovation-center')">
            <div class='nav-content'>
              <img src="/assets/images/operations.png" alt="">
              <p>Inovation Center</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="openPage('employee-assets/home')">
            <div class='nav-content'>
              <img src="/assets/icon/claims.svg" alt="">
              <p>Employee Assets</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="openPage('operations/home')">
            <div class='nav-content'>
              <img src="/assets/images/operations.png" alt="">
              <p>{{ 'homepage.operations'|translate }}</p>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('ferry-management/home')">
            <div class='nav-content'>
              <img src="/assets/images/operations.png" alt="">
              <p>Ferry Management</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="openPage('compliance-form/home')">
            <div class='nav-content'>
              <img src="/assets/images/store-audit.svg" alt="">
              <p>Compliance Form</p>
              <ion-badge slot="end" color="danger" *ngIf="compliancePing" style="position: absolute; top: 10px;">new</ion-badge>
            </div>
          </div>
        </ion-col>
        <ion-col>
          <div class='nav-box' (click)="openPage('hostel-management/home')">
            <div class='nav-content'>
              <img src="/assets/images/bookings.svg" alt="">
              <p>Hostel Management</p>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col>
          <div class='nav-box' (click)="openPage('booking-cafeteria/home')">
            <div class='nav-content'>
              <img src="/assets/images/bookings.svg" alt="">
              <p>Cafeteria Booking</p>
            </div>
          </div>
        </ion-col>
        <ion-col>
        </ion-col>
        <ion-col>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div> -->

</ion-content>
