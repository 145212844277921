import { Component, OnInit } from '@angular/core';
import { NavController, AlertController, ModalController, Platform } from '@ionic/angular';
import { NavParamsService } from 'src/app/nav-params.service';
import { Storage } from '@ionic/storage';
import { Browser } from '@capacitor/browser';
import config from 'src/config';
import axios from 'axios';

@Component({
  selector: 'app-more',
  templateUrl: './more.page.html',
  styleUrls: ['./more.page.scss'],
})
export class MorePage implements OnInit {

  public isLoading = true;
  public compliancePing = '';
  public url = '';
  public nonTradeUrl = '';

  constructor(
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    private storage: Storage,
    public navParams: NavParamsService,
    public modalCtrl: ModalController,
    public platform: Platform,
  ) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openPage(pageToOpen) {
    this.modalCtrl.dismiss({
      movedPage: true,
      page: pageToOpen
    });
  }
  
  getUserData() {
    this.storage.get(config.ACCESS_TOKEN_IDENTIFIER).then(accessToken => {
      axios.get(`${config.API_URL}/auth/user-data`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json;'
        }
      }).then(json => {
        const data = json.data;
        if (data.error) {
          if (data.status_code === 'CHANGE_PASSWORD') {
            this.navCtrl.navigateRoot('/change-password');
          } else {
            this.alertCtrl.create({
              header: 'Error',
              message: data.error_message,
              buttons: ['Ok']
            }).then(alert => {
              alert.present();
            });
          }
        } else {
          if (data.changed_password !== 1) {
            this.navCtrl.navigateRoot('/change-password');
          } else {
            this.isLoading = false;
            this.compliancePing = data.compliance_ping;
          }
        }
      }).catch(error => {
        console.error(error);
        this.alertCtrl.create({
          header: 'Error',
          message: 'Sorry, something went wrong. Please check your internet connection and try again.',
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      });
    });
  }

  getToken() {
    this.storage.get(config.ACCESS_TOKEN_IDENTIFIER).then(accessToken => {
      axios.get(`${config.API_URL}/city-job/generate-token`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json;'
        }
      }).then(json => {
        this.isLoading = false;
        const data = json.data;
        if (data.error) {
          if (data.status_code === 'CHANGE_PASSWORD') {
            this.navCtrl.navigateRoot('/change-password');
          } else {
            this.alertCtrl.create({
              header: 'Error',
              message: data.error_message,
              buttons: ['Ok']
            }).then(alert => {
              alert.present();
            });
          }
        } else {
          // this.url = data.url;
          // console.log(this.url);
          if (this.platform.is('cordova') || this.platform.is('capacitor')) {
            Browser.open({
              url: data.url,
              windowName: '_system'
            });
          } else {
            window.open(data.url, '_blank');
          }
        }
      }).catch(error => {
        console.error(error);
        this.alertCtrl.create({
          header: 'Error',
          message: 'Sorry, something went wrong. Please check your internet connection and try again.',
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      });
    });
  }

  ngOnInit() {
    this.getUserData();
  }

}
