<ion-header>
  <ion-toolbar color='tertiary'>
    <ion-buttons slot='start'>
      <ion-button (click)='closeModal()'>
        <ion-icon name="chevron-back-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Create Group Chat</ion-title>
    <ion-buttons slot='end'>
      <ion-button (click)='createGroupChat()' *ngIf="!isCreating">
        Create
      </ion-button>
      <ion-button *ngIf="isCreating">
        <ion-spinner color='white' style='color: white !important'></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-list-header>Group Name</ion-list-header>
    <ion-item lines="full">
      <ion-input [(ngModel)]="groupName" placeholder="Enter Group Name"></ion-input>
    </ion-item>
    <ion-list-header>Select Attendees</ion-list-header>
    <!-- <ion-item lines="full">
      <ion-input placeholder="Search by employee code" [(ngModel)]="employeeCode"></ion-input>
    </ion-item> -->
    <ion-item lines="full">
      <ion-input placeholder="Search by employee name" [(ngModel)]="employeeName"></ion-input>
    </ion-item>
    <ion-item lines="full">
      <ion-input placeholder="Search by Job title" [(ngModel)]="employeeJobTitle"></ion-input>
    </ion-item>
  </ion-list>
  <ion-button (click)='searchEmployees()' *ngIf="!isLoading" color='tertiary' expand='fill' style='display: block; width: 80%; margin: 0 auto; margin-top: 10px'>Search Employees</ion-button>
  <ion-button *ngIf="isLoading" disabled="true" color='tertiary' expand='fill' style='display: block; width: 80%; margin: 0 auto; margin-top: 10px'>
    <ion-spinner size="small" style="margin-right: 5px"></ion-spinner>
    Search Employees
  </ion-button>

  <ion-list *ngIf="hasSearched">
    <ion-list-header>Select Employee</ion-list-header>
    <ion-item lines="full" *ngFor="let employee of employees">
      <ion-avatar slot="start">
        <div class="avatar" [ngStyle]="{'background-image': 'url(' + employee.signed_profile_picture_url + ')'}">
        </div>
      </ion-avatar>
      <div class="employee-list-chat">
        <h4 class="employee-list-chat-name"><strong>{{ employee.first_name }} {{ employee.last_name }}</strong> <span>({{ employee.employee_number }})</span></h4>
        <p class="employee-list-chat-job-title" *ngIf="employee.ess_employee_profile != undefined && employee.ess_employee_profile.full_job_title != undefined && employee.ess_employee_profile.full_job_title != ''">
          {{ employee.ess_employee_profile.full_job_title }}
        </p>
        <p class="employee-list-chat-job-title" *ngIf="employee.ess_employee_profile != undefined && (employee.ess_employee_profile.full_job_title == undefined || employee.ess_employee_profile.full_job_title == '')">
          {{ employee.ess_employee_profile.job_title }}
        </p>
        <p class="employee-list-chat-company" *ngIf="employee.ess_company != undefined">
          {{ employee.ess_company.name }}
        </p>
      </div>
      <ion-checkbox 
        slot="end" 
        (ionChange)="manageCheckboxEvent(employee.id)"
        [(ngModel)]="employee.is_checked"
      ></ion-checkbox>
    </ion-item>
  </ion-list>
</ion-content>
