import { Component, OnInit, Input } from '@angular/core';
import { FormatNumberService } from 'src/app/format-number.service';
import { ModalController, AlertController, NavController } from '@ionic/angular';
import { EventService } from 'src/app/event.service';
import { Storage } from '@ionic/storage';
import { NavParamsService } from 'src/app/nav-params.service';
import config from 'src/config';
import axios from 'axios';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.page.html',
  styleUrls: ['./pop-up.page.scss'],
})
export class PopUpPage implements OnInit {

  public isViewLoading: any = false;
  public announcement: any;

  constructor(
    public formatNumber: FormatNumberService,
    public navParams: NavParamsService,
    public modalCtrl: ModalController,
    public storage: Storage,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    public events: EventService
  ) { 
    this.announcement = this.navParams.getNavParams().announcement;
    setTimeout(() => {
      this.isViewLoading = false;
    }, 200);

  }

  /**
   * set announcement as read
   */
  async setAnnouncementAsRead(){
    const token = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);

    axios.post(`${config.API_URL}/popup/mark-as-viewed`, {
      announcement_id: this.announcement.id
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  ngOnInit() {
    this.setAnnouncementAsRead();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
