import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule )
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'ess',
    loadChildren: () => import('./ess/ess.module').then( m => m.EssPageModule)
  },
  {
    path: 'online-meetings',
    loadChildren: () => import('./online-meetings/online-meetings.module').then( m => m.OnlineMeetingsPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    path: 'qr-location-checkin',
    loadChildren: () => import('./qr-location-checkin/qr-location-checkin.module').then( m => m.QrLocationCheckinPageModule)
  },
  {
    path: 'innovation-center',
    loadChildren: () => import('./innovation-center/innovation-center.module').then( m => m.InnovationCenterPageModule)
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./live-chat/live-chat.module').then( m => m.LiveChatPageModule)
  },
  {
    path: 'booking-cafeteria',
    loadChildren: () => import('./booking-cafeteria/booking-cafeteria.module').then( m => m.BookingCafeteriaPageModule)
  },
  {
    path: 'ferry-management',
    loadChildren: () => import('./ferry-management/ferry-management.module').then( m => m.FerryManagementPageModule)
  },
  {
    path: 'hostel-management',
    loadChildren: () => import('./hostel-management/hostel-management.module').then( m => m.HostelManagementPageModule)
  },
  {
    path: 'activity-list',
    loadChildren: () => import('./activity-list/activity-list.module').then( m => m.ActivityListPageModule)
  },
  {
    path: 'covid',
    loadChildren: () => import('./covid/covid.module').then( m => m.CovidPageModule)
  },
  {
    path: 'one-stop-service-center',
    loadChildren: () => import('./one-stop-service-center/one-stop-service-center.module').then( m => m.OneStopServiceCenterPageModule)
  },
  {
    path: 'employee-assets',
    loadChildren: () => import('./employee-assets/employee-assets.module').then( m => m.EmployeeAssetsPageModule)
  },
  {
    path: 'work-space',
    loadChildren: () => import('./work-space/work-space.module').then( m => m.WorkSpacePageModule)
  },
  {
    path: 'my-voice',
    loadChildren: () => import('./my-voice/my-voice.module').then( m => m.MyVoicePageModule)
  },
  {
    path: 'business-intelligence',
    loadChildren: () => import('./business-intelligence/business-intelligence.module').then( m => m.BusinessIntelligencePageModule)
  },
  {
    path: 'dispatcher',
    loadChildren: () => import('./dispatcher/dispatcher.module').then( m => m.DispatcherPageModule)
  },
  {
    path: 'qa-audit',
    loadChildren: () => import('./qa-audit/qa-audit.module').then( m => m.QaAuditPageModule)
  },
  {
    path: 'store-operations',
    loadChildren: () => import('./store-operations/store-operations.module').then( m => m.StoreOperationsPageModule)
  },
  {
    path: 'store-operations-cp',
    loadChildren: () => import('./store-operations-cp/store-operations-cp.module').then( m => m.StoreOperationsCpPageModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'helpdesk',
    loadChildren: () => import('./helpdesk/helpdesk.module').then( m => m.HelpdeskPageModule)
  },
  {
    path: 'mpp',
    loadChildren: () => import('./mpp/mpp.module').then( m => m.MppPageModule)
  },
  {
    path: 'pick-work-location',
    loadChildren: () => import('./components/pick-work-location/pick-work-location.module').then( m => m.PickWorkLocationPageModule)
  },
  {
    path: 'employee-qr-scan',
    loadChildren: () => import('./employee-qr-scan/employee-qr-scan.module').then( m => m.EmployeeQrScanPageModule)
  },
  {
    path: 'office-tools-new',
    loadChildren: () => import('./office-tools-new/office-tools-new.module').then( m => m.OfficeToolsNewPageModule)
  },
  {
    path: 'new-purchase-requisition',
    loadChildren: () => import('./new-purchase-requisition/new-purchase-requisition.module').then( m => m.NewPurchaseRequisitionPageModule)
  },
  {
    path: 'application-library',
    loadChildren: () => import('./application-library/application-library.module').then( m => m.ApplicationLibraryPageModule)
  },
  {
    path: 'event-registration/home',
    loadChildren: () => import('./event-registration/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'event-registration/ticket-details',
    loadChildren: () => import('./event-registration/ticket-details/ticket-details.module').then( m => m.TicketDetailsPageModule)
  },
  {
    path: 'event-registration/history',
    loadChildren: () => import('./event-registration/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'event-registration/list',
    loadChildren: () => import('./event-registration/list/list.module').then( m => m.ListPageModule)
  },
  {
    path: 'event-registration/waiting-room',
    loadChildren: () => import('./event-registration/waiting-room/waiting-room.module').then( m => m.WaitingRoomPageModule)
  },
  {
    path: 'event-registration/faq',
    loadChildren: () => import('./event-registration/faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'budget-entry',
    loadChildren: () => import('./budget-entry/budget-entry.module').then( m => m.BudgetEntryPageModule)
  },
  {
    path: 'roadshow',
    loadChildren: () => import('./roadshow/roadshow.module').then( m => m.RoadshowPageModule)
  },
  {
    path: 'internal-vacancy',
    loadChildren: () => import('./internal-vacancy/internal-vacancy.module').then( m => m.InternalVacancyPageModule)
  },
  {
    path: 'attachment-view',
    loadChildren: () => import('./attachment-view/attachment-view.module').then( m => m.AttachmentViewPageModule)
  },
  {
    path: 'service-rating',
    loadChildren: () => import('./service-rating/service-rating.module').then( m => m.ServiceRatingPageModule)
  },
  {
    path: 'employee-confirmation',
    loadChildren: () => import('./employee-confirmation/employee-confirmation.module').then( m => m.EmployeeConfirmationPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'contract-license-system',
    loadChildren: () => import('./contract-license-system/contract-license-system.module').then( m => m.ContractManagementPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
