import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavParamsService {

  public navParams: any = {};

  setNavParams(navParams) {
    this.navParams = navParams;
  }

  getNavParams() {
    return this.navParams;
  }

}
