import { Component, OnInit, Input } from '@angular/core';
import { FormatNumberService } from 'src/app/format-number.service';
import { NavParams, ModalController, AlertController, NavController } from '@ionic/angular';
import { EventService } from 'src/app/event.service';
import { Storage } from '@ionic/storage';
import config from 'src/config';
import axios from 'axios';

@Component({
  selector: 'app-comment-likes',
  templateUrl: './comment-likes.page.html',
  styleUrls: ['./comment-likes.page.scss'],
})
export class CommentLikesPage implements OnInit {

  comment: any;
  likes: any = [];
  isViewLoading = true;

  constructor(public formatNumber: FormatNumberService,
              public navParams: NavParams,
              public modalCtrl: ModalController,
              public storage: Storage,
              public alertCtrl: AlertController,
              public navCtrl: NavController,
              public events: EventService) {
    this.comment = navParams.get('comment');
  }

  ngOnInit() {
    this.getLikes();
  }

  async getLikes() {
    this.isViewLoading = true;

    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.get(`${config.API_URL}/ess/Forum/comment-likes/${this.comment.id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    }).then(response => {
      const jsonData = response.data;
      if (jsonData.error) {
        if (jsonData.status_code === 'CHANGE_PASS') {
          this.alertCtrl.create({
            header: 'Error',
            message: 'Please change your password to continue.',
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
            this.navCtrl.navigateRoot('/change-password');
          });
        } else if (jsonData.status_code === 401 || jsonData.status_code === '401') {
          this.storage.remove(config.STORAGE_TOKEN_IDENTIFIER).then(() => {
            this.alertCtrl.create({
              header: 'Error',
              message: 'Sorry, something went wrong. Please log in again.',
              buttons: ['Ok']
            }).then(alert => {
              alert.present();
              this.events.publish('user:logout', true);
            });
          });
        } else {
          this.alertCtrl.create({
            header: 'Error',
            message: jsonData.error_message,
            buttons: ['Ok']
          }).then(alert => {
            alert.present();
          });
        }

        this.closeModal();
      } else {
        this.isViewLoading = false;
        this.likes = jsonData.likes;
        this.comment.likes_count = this.likes.length;
      }
    }).catch(error => {
      this.closeModal();
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  closeModal() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss();
  }

}
