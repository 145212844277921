import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import axios from 'axios';
import config from 'src/config';

@Injectable({
  providedIn: 'root'
})
export class SunfishSsoClientService {

  private readonly REQUEST_TOKEN_URL: string = config.API_URL + '/sunfish-sso/request-token';
  private readonly SSO_BACKEND_LINK = config.SITE_URL + '/web-api/sunfish-sso';

  constructor(
    private platform: Platform,
    public storage: Storage,
  ) { }

  requestToken(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const token = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER)
      try {
        const response = await axios.post(this.REQUEST_TOKEN_URL, {}, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const responseData = response.data || {};
        if (!responseData?.status) {
          resolve({
            error: true,
            errorMessage: 'Something went wrong. Please try again later!'
          })
          return
        }

        resolve({
          error: false,
          data: {
            url: decodeURI(responseData.data)
          }
        })
      } catch (error) {
        const errorMessage = error?.response?.data?.error_message || null;
        resolve({
          error: true,
          errorMessage: errorMessage || 'Something went wrong. Please try again later!'
        })
      }
    });
  }

  openSSOPage(url: string): void {
    if (this.platform.is('cordova') || this.platform.is('capacitor')) {
      Browser.open({
        url: url,
        windowName: '_system'
      })
    } else {
      window.open(url, '_blank');
    }
  }
}
