import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UploadFileModalPageRoutingModule } from './upload-file-modal-routing.module';

import { UploadFileModalPage } from './upload-file-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UploadFileModalPageRoutingModule
  ],
  declarations: [UploadFileModalPage]
})
export class UploadFileModalPageModule {}
